import React from 'react';
import ReactDOM from 'react-dom';
import 'styles/index.scss';
import { Loading } from '@place2book/react-components';

import loadConfig from 'utils/loadConfig';

// eslint-disable-next-line @typescript-eslint/no-var-requires
await import('smoothscroll-polyfill').then((result) => result.polyfill());

const App = React.lazy(() => import('./App'));

loadConfig().then(() => {
  ReactDOM.render(
    <React.Suspense fallback={<Loading />}>
      <App />
    </React.Suspense>,
    document.getElementById('root')
  );
});

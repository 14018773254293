import { Config } from 'config/types';

const loadConfig = async (): Promise<void> => {
  const environment = process.env.REACT_APP_ENV;

  if (!environment) throw new Error('App environment is undefined');
  if (!['production', 'staging', 'development', 'test'].includes(environment)) {
    throw new Error("App environment has to be one of ['production', 'staging', 'development', 'test']");
  }

  // Load defaults, and override with environment variables
  // const config: Config = (await (await fetch(`/config.json`)).json()) as Config;
  const config: Config = (await import(`config/${environment}`)).default as unknown as Config;

  window.config = config;

  if (!window.config.environmentAllows) throw new Error('Environment allows is undefined');
};

export default loadConfig;
